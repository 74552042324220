<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="organizationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching organization data
      </h4>
      <div class="alert-body">
        No organization found with this organization id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-organizations-list'}"
        >
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>

    <template v-if="organizationData">
      <!-- First Row -->
      <b-row>
        <!-- <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        > -->
        <b-col
          cols="12"
        >
          <organization-view-organization-info-card :organization-data="organizationData" />
        </b-col>
        <!-- <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <organization-view-organization-plan-card />
        </b-col> -->
      </b-row>

      <b-row>
        <!-- <b-col
          cols="12"
          lg="6"
        >
          <organization-view-organization-timeline-card />
        </b-col> -->
        <b-col
          cols="12"
        >
          <organization-view-organization-teams-card :organization-data="organizationData" :action="false"/>
        </b-col>
      </b-row>
      <!-- <organization-view-organization-timetable-card></organization-view-organization-timetable-card> -->

      <!-- <invoice-list /> -->
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import organizationStoreModule from '../organizationStoreModule'
import OrganizationViewOrganizationInfoCard from './OrganizationViewOrganizationInfoCard.vue'
import OrganizationViewOrganizationPlanCard from './OrganizationViewOrganizationPlanCard.vue'
import OrganizationViewOrganizationTimelineCard from './OrganizationViewOrganizationTimelineCard.vue'
import OrganizationViewOrganizationTeamsCard from './OrganizationViewOrganizationTeamsCard.vue'
import OrganizationViewOrganizationPremissionsCard from './OrganizationViewOrganizationPermissionsCard.vue'
import OrganizationViewOrganizationTimetableCard from './OrganizationViewOrganizationTimetableCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    OrganizationViewOrganizationInfoCard,
    OrganizationViewOrganizationPlanCard,
    OrganizationViewOrganizationTimelineCard,
    OrganizationViewOrganizationTeamsCard,
    OrganizationViewOrganizationPremissionsCard,
    OrganizationViewOrganizationTimetableCard

    // InvoiceList,
  },
  setup() {
    const organizationData = ref(null)
    let checkOrganizationData = false

    const USER_APP_STORE_MODULE_NAME = 'app-organization'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, organizationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-organization/fetchOrganization', { id: router.currentRoute.params.id })
      .then(response => { 
        organizationData.value = response.data.response
      })
      .catch(error => {
        if (error.response.status === 404) {
          organizationData.value = undefined
        }
      })

    return {
      organizationData,
    }
  },
  // data () {
  //   return {
  //     checkOrganizationData: false
  //   }
  // }
}
</script>

<style>

</style>
