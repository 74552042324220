<template>
  <b-card>

    <b-row>

      <!-- Organization Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Organization Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="organizationData.avatar"
            :text="avatarText(organizationData.fullName)"
            :variant="`light-${resolveOrganizationRoleVariant(organizationData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ organizationData.name }}
              </h4>
              <span class="card-text">{{ organizationData.name }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'organizations-edit', params: { id: organizationData.id } }"
                variant="primary"
              >
                Засах
              </b-button>
              <!-- <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Устгах
              </b-button> -->
            </div>
          </div>
        </div>

        <!-- Organization Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="OrganizationIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Байгууллагын нэр</span>
            </th>
            <td class="pb-50">
              {{ organizationData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Төлөв</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="organizationData.is_active ? 'success': 'danger'"
              >
                {{ organizationData.is_active ? 'идэвхтэй': 'идэвхгүй' }}
              </b-badge>
            </td>
          </tr>
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ organizationData.role }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ organizationData.country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ organizationData.contact }}
            </td>
          </tr> -->
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useOrganizationsList from '../organizations-list/useOrganizationsList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveOrganizationRoleVariant } = useOrganizationsList()
    return {
      avatarText,
      resolveOrganizationRoleVariant,
    }
  },
}
</script>

<style>

</style>
