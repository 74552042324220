<template>
  <b-card
    no-body
  >
    <b-card-body>
      <div class="d-flex flex-wrap space-between">
        <div class="mb-2 col-md-9 col-12">
          <b-card-title>Багийн жагсаалт</b-card-title>
          <b-card-sub-title>Тухайн байгууллагад харьяалагдах багууд</b-card-sub-title>
        </div>
        <div class="mb-1 col-md-3 col-12" v-if="action">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-login
            variant="outline-primary"
            @click="loaded = true"
          >
            Баг нэмэх
          </b-button>
        </div>
      </div>
    </b-card-body>
    <b-table
    responsive
    :items="organizationData.teams"
    :fields="fields"
    class="mb-0"
  >
    <template #cell(Phone)="data">
      <span class="text-nowrap">
        {{ data.value }}
      </span>
    </template>

    <!-- Optional default data cell scoped slot -->
    <template #cell()="data">
      {{ data.value }}
    </template>
    <!-- Column: is_active -->
    <template #cell(is_active)="data">
      <b-badge
        pill
        :variant="data.item.is_active ? 'success': 'danger'"
      >
        {{ data.item.is_active ? 'идэвхтэй': 'идэвхгүй' }}
      </b-badge>
    </template>
    <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="action">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon __table_action_button mr-1"
              v-b-tooltip.hover.v-primary title="Засах"
              v-b-modal.modal-login @click="show(data.item)"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="data.item.is_active ? 'outline-primary' : 'primary'"
            class="btn-icon __table_action_button"
            @click="confirmDelete(data.item)" v-b-tooltip.hover.v-primary :title="'Устгах'"
            size="sm"
          >
            <feather-icon :icon="'TrashIcon'" />
          </b-button>
        </template>
  </b-table>
  <b-modal id="delete-confirm-modal" @ok="deleteTeam" ok-title="Тийм"
      cancel-title="Үгүй">
        <template #modal-title>
          Устгахдаа итгэлтэй байна уу?
        </template>
        <p>Та энэ багийг устгахдаа итгэлтэй байна уу?</p>
      </b-modal>
  <!-- modal login-->
    <b-modal
      v-if="loaded"
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Хадгалах"
      cancel-title="Болих"
      centered
      title="Баг нэмэх"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form>
        <b-form-group>
          <label for="email">Багийн нэр</label>
          <b-form-input
            id="email"
            type="email"
            placeholder="Багийн нэр"
            v-model="teamData.name"
          />
        </b-form-group>
        <b-form-group>
          <label for="password">Дараалал</label>
          <b-form-spinbutton
            id="sort-order"
            v-model="teamData.sort_order"
            min="1"
            max="100"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormSpinbutton, BDropdown, 
    BDropdownItem, BBadge, VBTooltip, BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BFormSpinbutton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
    action: {
      type: Boolean,
      required: true,
    },
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted () {
    // this.teamData.organization_name = this.organizationData.name
    // store.dispatch('app-organization/fetchTeamsByFilter', { organization_name: this.teamData.organization_name })
    // .then(response => {
    //   this.items = response.data.response
    // })
  },
  data () {
    return {
      teamData: {
        name: null,
        sort_order: 0
      },
      loaded: false,
      fields: [
        // 'id',
        {
          key: 'name', label: 'Багийн нэр',
        },
        {
          key: 'organization_name', label: 'Байгууллагын нэр',
        },
        {
          key: 'created_by', label: 'Үүсгэсэн',
        },
        {
          key: 'created_at', label: 'Үүсгэсэн огноо',
        },
        {
          key: 'is_active', label: 'Төлөв',
        },
        {
          key: 'actions', label: ''
        }
      ],
      items: [],
    }
  },
  methods: {
    resetModal () {
      this.teamData.name = ''
    },
    handleOk () {
      this.teamData.organization_name = this.organizationData.name

      if (this.teamData.id === undefined) {
        store.dispatch('app-organization/addTeam', this.teamData)
        .then(() => {
          store.dispatch('app-organization/fetchTeamsByFilter', { organization_name: this.teamData.organization_name })
          .then(response => {
            this.organizationData.teams = response.data.response
            this.loaded = false
          })
          // emit('refetch-data')
          // emit('update:is-add-new-organization-sidebar-active', false)
        })
      } else {
        store.dispatch('app-organization/editTeam', this.teamData)
        .then(() => {
          store.dispatch('app-organization/fetchTeamsByFilter', { organization_name: this.teamData.organization_name })
          .then(response => {
            this.organizationData.teams = response.data.response
            this.loaded = false
          })
          // emit('refetch-data')
          // emit('update:is-add-new-organization-sidebar-active', false)
        })
      }
    },
    show (param) {
      // store.dispatch('app-organization/fetchTeam', { id: param })
      // .then(response => {
        this.teamData = {...param}
        this.loaded = true
      // })
    },
    confirmDelete(teamData) {
      this.teamData = teamData;
      this.$bvModal.show('delete-confirm-modal');
    },
    deleteTeam () {
      if(this.teamData){
        store.dispatch('app-organization/deleteTeam', this.teamData)
        .then(response => {
          console.log(response)
          if (response.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              this.$router.go()  // Refresh the current route
            }, 1000) 
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.teamData = null; // Reset the organization after deletion
        });
      }
    }
  }
}
</script>

<style>

</style>
